/* istanbul ignore file */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {Category, PrincipleInfo, WeldingPrinciple, WeldingPrincipleDetails} from '../../../../../shared';
import {
  MOCK_BENDING_PRINCIPLES,
  MOCK_CATEGORIES,
  MOCK_CATEGORY_TO_PRINCIPLES,
  MOCK_WELDING_PRINCIPLE_DETAILS,
} from '../../data/mocks';
import { CategoryToPrinciples } from '../../types';
import { WeldingPrinciplesServiceInterface } from './welding-principles.service.interface';

@Injectable({
  providedIn: 'root',
})
export class WeldingPrinciplesServiceMock implements WeldingPrinciplesServiceInterface {
  public getCategories(): Observable<Category[]> {
    console.log('Fetch welding principle categories.');
    return of(MOCK_CATEGORIES);
  }

  public getCategoryToPrinciples(): Observable<CategoryToPrinciples[]> {
    console.log('Fetch welding principle categories.');
    return of(MOCK_CATEGORY_TO_PRINCIPLES);
  }

  public getPrinciples(categoryId: string): Observable<WeldingPrinciple[]> {
    console.log(`Fetch welding principles for category id '${categoryId}'`);
    return of(MOCK_BENDING_PRINCIPLES);
  }

  public getPrincipleDetails(id: string): Observable<WeldingPrincipleDetails> {
    console.log(`Fetch welding principle details for id '${id}'`);
    return of(MOCK_WELDING_PRINCIPLE_DETAILS);
  }

  getPrinciplesList(): Observable<PrincipleInfo[]> {
    return of([
      {
        index: 0,
        name: 'First Principle',
        principleId: 'FirstPrinciple'
      },
      {
        index: 1,
        name: 'Second Principle',
        principleId: 'SecondPrinciple'
      }
    ]);
  }
}
