import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Part, PartDetails} from '../../../../../../shared';

@Component({
  selector: 'lsb-part-list-item',
  templateUrl: './part-list-item.component.html',
  styleUrls: ['./part-list-item.component.scss'],
})
export class PartListItemComponent {
  @Input() part: Part;
  @Input() hideKeywords = false;

  @Output() select = new EventEmitter<string>();

  @Output() keywordSelect = new EventEmitter<{principleId: string, principleCatalogueId?: string}>();

  public partSelected(partId: string) {
    this.select.emit(partId);
  }

  public principleKeywordClicked(principleId: string) {
    const partDetail = this.part as PartDetails;
    const principleCatalogueId = partDetail?.weldingPrinciples?.find(p => p.id === principleId)?.principleId;

    this.keywordSelect.emit({ principleId, principleCatalogueId });
  }

}
