import { Component } from '@angular/core';
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import { BehaviorSubject, Observable, merge, of } from 'rxjs';
import {filter, flatMap, map, tap} from 'rxjs/operators';
import { WeldingPrinciplesService } from '../../services/welding-principles/welding-principles.service';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';
import { CategoryToPrinciples } from '../../types';

const BENDING_PRINCIPLES_PATH = '/bending-principles';
const DETAILS_PARAMETER = 'details';

interface PathParameters {
  principleCatalogueId: string;
  categoryId?: string;
  principleDetailsId?: string;
}

@Component({
  selector: 'lsb-welding-principles',
  templateUrl: './welding-principles.component.html',
  styleUrls: ['./welding-principles.component.scss'],
})
export class WeldingPrinciplesComponent {
  public readonly uiElementIds = UiElementIds;
  public categoryToPrinciples$: Observable<CategoryToPrinciples[] | undefined> | undefined;
  public selectedCategory = '';
  public selectedPrincipleId = '';

  constructor(
    private service: WeldingPrinciplesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const initialRoute = of(this.parseUrl(this.router.url));

    const routeChanges = router.events.pipe(
      filter((routerEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
      filter((routerEvent) => routerEvent.url.startsWith(BENDING_PRINCIPLES_PATH)),
      map((navigationEvent: NavigationEnd) => this.parseUrl(navigationEvent.url))
    );

    this.categoryToPrinciples$ = merge(initialRoute, routeChanges).pipe(
        flatMap((params) => this.service.getCategoryToPrinciples(params.principleCatalogueId)),
        tap((categoryToPrinciples) => {
          const params = this.parseUrl(this.router.url);
          this.selectedCategory = params.categoryId ?? '';
          this.selectedPrincipleId = params.principleDetailsId ?? '';
          if (this.navigatedToBasePath(params) && categoryToPrinciples.length > 0) {
            const targetId = categoryToPrinciples[0].category.id;
            this.router.navigate([params.principleCatalogueId, targetId], {
              relativeTo: this.route,
            });
          }
        }),
    );
  }

  /**
   * There could be 3 types of URL
   * /bending-principles/[principleCatalogueId]
   * /bending-principles/[principleCatalogueId]/[categoryId]
   * /bending-principles/[principleCatalogueId]/details/[principleId]
   */
  private parseUrl(url: string): PathParameters {
    const segments = this.router.parseUrl(url).root?.children[PRIMARY_OUTLET]?.segments;
    if (!segments || segments.length < 2 || segments[0].path !== BENDING_PRINCIPLES_PATH.replace('/', '')) {
      return { principleCatalogueId: '' };
    }
    // First segment is always 'bending-principles', we just skip it
    // Second segment is always principleCatalogueId
    const parametersResult: PathParameters = {
      principleCatalogueId: segments[1].path
    };
    if (segments.length === 3) {
      // In this case, third segment is categoryId
      parametersResult.categoryId = segments[2].path;
    }
    if (segments.length === 4 && segments[2].path === DETAILS_PARAMETER) {
      // Fourth parameter is principleId
      parametersResult.principleDetailsId = segments[3].path;
    }

    return parametersResult;
  }

  private navigatedToBasePath(pathParams: PathParameters): boolean {
    return pathParams.principleDetailsId === undefined && pathParams.categoryId === undefined;
  }
}
