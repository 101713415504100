<ng-container *ngIf="principles$ | async as principles">
  <xui-card
    *ngFor="let principle of principles"
    [routerLink]= "'../details/' + principle.id"
    [title]="principle.name"
    [preview]="principle.image | imageUrl"
  >
    {{ principle.description }}
  </xui-card>
</ng-container>
