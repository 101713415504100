import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PrincipleInfo, UsageTrackingMessageType} from '@shared';
import { UsageTrackingService } from '@trumpf-xguide/xguide';
import { ClickedUsageTracking } from '@trumpf-xguide/xguide/lib/usage-tracking/types';
import { MenuItemType } from '@trumpf/ux.ui-community';
import { UiElementIds } from '../usage-tracking/ui-element-ids';
import { TranslationHelper } from './translation.helpers';

export enum NavigationSlugs {
  DASHBOARD = 'dashboard',
  BENDING_PRINCIPLES = 'bending-principles',
  PARTS = 'parts',
  CALCULATIONS = 'calculations',
  MAIL_TO = 'mail-to',
  SETTINGS = 'settings',
  ABOUT = 'about',
  LOGIN = 'login',
}

export const generateNavigationMenu = (
  translateService: TranslateService,
  translations: TranslationHelper,
  principleInfos: PrincipleInfo[]
): MenuItemType[] => {
  const menu = [
    {
      label: translateService.instant(translations.MAIN_MENU.BENDING_PRINCIPLES),
      icon: 'bend-guide-icons:Bending.Machine.Side.Bent.Sheets.Parallel',
      slug: NavigationSlugs.BENDING_PRINCIPLES,
      items: principleInfos.map((principleInfo) => ({
        label: principleInfo.name,
        slug: principleInfo.principleId
      }))
    },
    {
      label: translateService.instant(translations.MAIN_MENU.PARTS),
      icon: 'bend-guide-icons:Package',
      slug: NavigationSlugs.PARTS,
    },

    {
      label: translateService.instant(translations.MAIN_MENU.CALCULATIONS),
      icon: 'bend-guide-icons:ProductionPlan.Gear',
      slug: NavigationSlugs.CALCULATIONS,
    },
    {
      label: translateService.instant(translations.XGUIDE.MENU.CONTACT_US_BUTTON),
      icon: 'bend-guide-icons:Envelope',
      slug: NavigationSlugs.MAIL_TO,
    },
  ];

  return menu;
};

export const navigateTo = (
  slug: NavigationSlugs,
  router: Router,
  usageTrackingService: UsageTrackingService,
  isPrincipleSlug: boolean = false
): void => {
  console.log('navigateTo', slug);
  let url: string;
  let uiElementId: UiElementIds;
  if (isPrincipleSlug) {
    url = `bending-principles/${slug}`;
    uiElementId = UiElementIds.BENDING_PRINCIPLES;
  } else {
    switch (slug) {
      case NavigationSlugs.DASHBOARD:
        url = 'dashboard';
        uiElementId = UiElementIds.DASHBOARD;
        break;
      case NavigationSlugs.PARTS:
        url = 'parts';
        uiElementId = UiElementIds.PARTS;
        break;
      case NavigationSlugs.CALCULATIONS:
        url = 'calculations';
        uiElementId = UiElementIds.CALCULATOR;
        break;
      case NavigationSlugs.SETTINGS:
        url = '/about/settings';
        uiElementId = UiElementIds.SETTINGS;
        break;
      case NavigationSlugs.ABOUT:
        url = '/about';
        uiElementId = UiElementIds.ABOUT;
        break;
      case NavigationSlugs.LOGIN:
      case NavigationSlugs.MAIL_TO:
      default:
        return;
    }
  }

  const usageTracking: ClickedUsageTracking = {
    currentUrl: router.url,
    clickedElement: uiElementId,
  };
  usageTrackingService.send(UsageTrackingMessageType.ElementClicked, usageTracking);

  router.navigateByUrl(url);
};
