import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  Category,
  HMI_USER_HEADER_NAME,
  HMI_USER_HEADER_VALUE, PrincipleInfo,
  WeldingPrinciple,
  WeldingPrincipleDetails,
} from '../../../../../shared';
import { environment } from '../../../environments/environment';
import { HmiService } from '../../shared/services/hmi/hmi.service';
import { LanguageService } from '../../shared/services/language/language.service';
import { CategoryToPrinciples } from '../../types';
import { WeldingPrinciplesServiceInterface } from './welding-principles.service.interface';

const BASE_ROUTE = 'welding-principles';

@Injectable({
  providedIn: 'root',
})
export class WeldingPrinciplesService implements WeldingPrinciplesServiceInterface {
  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private hmiService: HmiService,
  ) {}

  public getPrinciplesList(): Observable<PrincipleInfo[]> {
    console.log('getPrinciplesList', this.languageService.currentLanguage);
    const url = `${environment.apiUrl}/${BASE_ROUTE}/principles?lang=${this.languageService.currentLanguage}`;
    return this.http.get<PrincipleInfo[]>(url);
  }

  public getCategories(principleCatalogueId: string): Observable<Category[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/categories/${principleCatalogueId}?lang=${this.languageService.currentLanguage}`;
    return this.http.get<Category[]>(url);
  }

  public getCategoryToPrinciples(principleCatalogueId: string): Observable<CategoryToPrinciples[]> {
    const getCategoryToPrinciple: (category: Category) => Observable<CategoryToPrinciples> = (
      category,
    ) => this.getPrinciples(principleCatalogueId, category.id).pipe(map((principles) => ({ category, principles, principleCatalogueId })));

    return this.getCategories(principleCatalogueId).pipe(
      switchMap((categories) =>
        forkJoin(categories.map((category) => getCategoryToPrinciple(category))),
      ),
    );
  }

  public getPrinciples(principleCatalogueId: string, categoryId: string): Observable<WeldingPrinciple[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/principles/${principleCatalogueId}/${categoryId}?lang=${this.languageService.currentLanguage}`;
    return this.http.get<WeldingPrinciple[]>(url);
  }

  public getPrincipleDetails(principleCatalogueId: string, id: string): Observable<WeldingPrincipleDetails> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/principle-details/${principleCatalogueId}/${id}?lang=${this.languageService.currentLanguage}`;

    let httpOptions = {};
    if (this.hmiService.hideLogin) {
      // Because in HMI mode user is always unauthorized, there is a hack for getting principle details.
      httpOptions = {
        headers: new HttpHeaders({
          [HMI_USER_HEADER_NAME]: HMI_USER_HEADER_VALUE,
        }),
      };
    }
    return this.http.get<WeldingPrincipleDetails>(url, httpOptions);
  }
}
