import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import { WeldingPrinciple } from '../../../../../shared';
import { WeldingPrinciplesService } from '../../services/welding-principles/welding-principles.service';

@Component({
  selector: 'lsb-welding-principle-overview',
  templateUrl: './welding-principle-overview.component.html',
  styleUrls: ['./welding-principle-overview.component.scss'],
})
export class WeldingPrincipleOverviewComponent implements OnInit {
  public principles$: Observable<WeldingPrinciple[]> | undefined;

  constructor(private route: ActivatedRoute, private service: WeldingPrinciplesService) {}

  ngOnInit() {
    this.principles$ = this.route.paramMap.pipe(
      map((params) =>
          ({ principleCatalogueId: params.get('principleId'), categoryId: params.get('categoryId')})),
      filter((p) => p.principleCatalogueId !== null && p.categoryId !== null),
      switchMap((p) =>
          this.service.getPrinciples(p.principleCatalogueId ?? '', p.categoryId ?? '')),
    );
  }
}
