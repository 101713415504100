<xui-sub-menu class="menu">
  <xui-sub-menu-tab
    *ngFor="let categoryToPrinciples of categoryToPrinciples$ | async"
    xuiClickUsageTracking="{{ uiElementIds.BENDING_PRINCIPLES_CATEGORY }}"
    [specificElementIdentifier]="categoryToPrinciples.category.id"
    [routerLink]="categoryToPrinciples.principleCatalogueId + '/' + categoryToPrinciples.category.id"
    [selected]="categoryToPrinciples.category.id === selectedCategory"
    [label]="categoryToPrinciples.category.name"
    [class.hide]="categoryToPrinciples.principles.length <= 0"
  >
    <xui-sub-menu-sub-tab
      *ngFor="let principle of categoryToPrinciples.principles"
      xuiClickUsageTracking="{{ uiElementIds.BENDING_PRINCIPLES_CATEGORY_DETAILS }}"
      [specificElementIdentifier]="categoryToPrinciples.category.id + '-' + principle.id"
      [routerLink]="categoryToPrinciples.principleCatalogueId + '/details/' + principle.id"
      [selected]="principle.id === selectedPrincipleId"
      [label]="principle.name"
    ></xui-sub-menu-sub-tab>
  </xui-sub-menu-tab>
</xui-sub-menu>

<div class="content">
  <router-outlet></router-outlet>
</div>
